<ngx-spinner name="fetchspinner">
	<p style="color: white; font-size: 0.95rem; width: max-content">
		Searching our large database of vehicles and drivers....
	</p>
</ngx-spinner>
<ngx-spinner name="savespinner">
	<p style="color: white; font-size: 0.95rem; width: max-content">
		Saving your details. Please Wait...
	</p>
</ngx-spinner>
<ngx-spinner name="normalspinner"></ngx-spinner>
<div class="d-flex justify-content-end mr-3 " *ngIf="updateType=='edit'">
	<h3 class="m-0"> <span class="badge badge-secondary">Booking #{{booking_id}}</span></h3>
</div>

<div *ngIf="BigData">
	<form class="container-fluid" [formGroup]="BookingForm" appInvalidControlScroll>
		<div id="page-container m-2">
			<!-- Section - Booking Type -->
			<div class="section-container mt-0 px-4">
				<h2 class="border-secondary">
					{{updateType =='reaffiliate' ? 'edit' : updateType | titlecase}} a booking
				</h2>
				<div class="row px-4">
					<!-- Service Type -->
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label>
									Service Type
								</mat-label>
								<mat-select formControlName="service_type"
									(selectionChange)="onSelectionChangeServiceType($event)">
									<mat-option value="one_way">One Way ?</mat-option>
									<mat-option value="round_trip">Round Trip ?</mat-option>
									<mat-option value="charter_tour">Charter/Tour ?</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<!-- Transfer Type -->
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Transfer Type
								</mat-label>
								<mat-select formControlName="transfer_type" class="font-weight-bolder"
									(selectionChange)="changeTransferType($event.value)">
									<mat-option value="airport_to_city">Airport To City ?</mat-option>
									<mat-option value="airport_to_airport">Airport To Airport ?</mat-option>
									<mat-option value="airport_to_cruise">Airport To Cruise Port ?</mat-option>
									<mat-option value="city_to_city">City To City ?</mat-option>
									<mat-option value="city_to_airport">City To Airport ?</mat-option>
									<mat-option value="city_to_cruise">City To Cruise Port ?</mat-option>
									<mat-option value="cruise_to_airport">Cruise Port To Airport ?</mat-option>
									<mat-option value="cruise_to_city">Cruise Port to City ?</mat-option>
									<!-- <mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option> -->
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<!-- Return Transfer Type -->
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'round_trip'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Return Transfer Type
								</mat-label>
								<mat-select formControlName="return_transfer_type" [(ngModel)]="return_transfer_type"
									(selectionChange)="changeReturnTransferType($event.value)">
									<mat-option value="airport_to_city">Airport To City ?</mat-option>
									<mat-option value="airport_to_airport">Airport To Airport ?</mat-option>
									<mat-option value="airport_to_cruise">Airport To Cruise Port ?</mat-option>
									<mat-option value="city_to_city">City To City ?</mat-option>
									<mat-option value="city_to_airport">City To Airport ?</mat-option>
									<mat-option value="city_to_cruise">City To Cruise Port ?</mat-option>
									<mat-option value="cruise_to_airport">Cruise Port To Airport ?</mat-option>
									<mat-option value="cruise_to_city">Cruise Port to City ?</mat-option>
									<!-- <mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option> -->
								</mat-select>
							</mat-form-field>
						</div>
					</div>

					<!-- Hours -->
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'charter_tour'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label>
									Number of Hours
								</mat-label>
								<input type="number" matInput placeholder="Enter Hours"
									formControlName="number_of_hours" [(ngModel)]="number_of_hours"
									(keyup)="handleNoOfHours($event.target.value)" (focus)="$event.target.select()" />
								<span matSuffix>Hrs.</span>
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
			<div class="section-container">
				<div formGroupName="loose_customer">
					<!-- Personal Information -->
					<div class="px-4">
						<h4>
							Personal Information
						</h4>
						<div class="row mx-1 note">Note : An account will be created for this Account information</div>
						<div class="row">
							<!-- First Name -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										First Name
									</mat-label>
									<input matInput placeholder="First Name" type="text" formControlName="first_name"
										(focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="(submitBookingForm && LooseCustomer.first_name.invalid) || (LooseCustomer.first_name.touched && LooseCustomer.first_name.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.first_name.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.first_name.errors?.whitespace">
											Extra White Space are not Allowed
										</p>
									</div>
								</div>
							</div>

							<!-- Middle Name -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label>
										Middle Name
									</mat-label>
									<input matInput placeholder="Middle Name" type="text" formControlName="middle_name"
										(focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="LooseCustomer.middle_name.invalid && (LooseCustomer.middle_name.touched || LooseCustomer.middle_name.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.middle_name.errors?.whitespace">
											Extra White Space are not Allowed
										</p>
									</div>
								</div>
							</div>

							<!-- Last Name -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										Last Name
									</mat-label>
									<input matInput placeholder="Last Name" type="text" formControlName="last_name"
										(focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="(submitBookingForm && LooseCustomer.last_name.invalid) || (LooseCustomer.last_name.touched && LooseCustomer.last_name.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.last_name.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.last_name.errors?.whitespace">
											Extra White Space are not Allowed
										</p>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<!-- Email -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										Email
									</mat-label>
									<input matInput placeholder="Email" type="text" formControlName="email"
										[email]="true" (focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="LooseCustomer.email.invalid && (LooseCustomer.email.touched || LooseCustomer.email.dirty)">
										<p class="text-danger text-lg m-0" *ngIf="LooseCustomer.email.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0" *ngIf="LooseCustomer.email.errors?.pattern">
											Please enter a valid Email Id
										</p>
									</div>
								</div>
							</div>

							<!-- Phone -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="mat-custom-phone m-0 cellLabelClass">
									<label
										class="mat-form-field-label required-field mat-empty mat-form-field-empty labelClass">Phone
									</label>
									<input matInput formControlName="phone" ng2TelInput [ng2TelInputOptions]="{
												preferredCountries: ['us', 'ca', 'mx', 'gb']
											}" placeholder="1234567890" class="phoneNumberClass" (intlTelInputObject)="LCTelInputObject($event)"
										(keyup)="handleLooseCustomerPhone($event)"
										(countryChange)="onLCTeleCountryChange($event)"
										(focus)="$event.target.select()" />
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && (is_loose_customer_unique || LooseCustomer.phone.invalid)) || (LooseCustomer.phone.touched && LooseCustomer.phone.dirty)">
										<p class="text-danger text-lg m-0" *ngIf="LooseCustomer.phone.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.phone.errors?.pattern;else otherError">
											Only Numbers are Allowed
										</p>
										<ng-template #otherError>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.phone.errors?.minlength">
												Minimum length should be 4
											</p>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.phone.errors?.maxlength">
												Maximum length should be 15
											</p>
										</ng-template>
										<p class="text-danger text-lg m-0" *ngIf="is_loose_customer_unique">
											This Phone Number is already registered. Please enter
											any other.
										</p>
									</div>
								</div>
							</div>

							<!-- Address -->
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label>
										Address
									</mat-label>
									<input type="text" matInput placeholder="Type your address"
										matGoogleMapsAutocomplete
										(onAutocompleteSelected)="fillLooseCustomerAddress($event)"
										(focus)="$event.target.select()" />
								</mat-form-field>
							</div>
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label>
										No. of Passengers <span class="asterisk"></span>
									</mat-label>
									<mat-select formControlName="total_passengers">
										<mat-option *ngFor="let option of booking_params.numbers" [value]="option">
											{{ option }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4 looseError">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label>
										Luggage Count <span class="asterisk"></span>
									</mat-label>
									<mat-select formControlName="luggage_count">
										<mat-option *ngFor="let option of booking_params.numbers" [value]="option">
											{{ option }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
					</div>

					<!-- Personal Information Ends -->

					<!-- Credit Card Details -->
					<div id="credit-card-details" class="mt-4 px-4" formGroupName="card_details">
						<h4>
							Card Details
						</h4>
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										Name of Card Holder
									</mat-label>
									<input matInput placeholder="First & Last Name" type="text" formControlName="name"
										(focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && LooseCustomer.card_details.controls.name.invalid) || (LooseCustomer.card_details.controls.name.touched && LooseCustomer.card_details.controls.name.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.name.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.name.errors?.whitespace">
											Extra White Space are not Allowed
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										Card Number
									</mat-label>
									<input matInput placeholder="Card No" type="text" formControlName="card_number"
										(focus)="$event.target.select()">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && LooseCustomer.card_details.controls.card_number.invalid) || (LooseCustomer.card_details.controls.card_number.touched && LooseCustomer.card_details.controls.card_number.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.card_number.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.card_number.errors?.pattern;else cardLength">
											Only Numbers are Allowed
										</p>
										<ng-template #cardLength>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.card_details.controls.card_number.errors?.minlength">
												Minimum length should be 14
											</p>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.card_details.controls.card_number.errors?.maxlength">
												Maximum length should be 20
											</p>
										</ng-template>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">Expiry Month</mat-label>
									<input type="number" matInput formControlName="exp_month" [matAutocomplete]="auto"
										(keyup)="handleChangeMonth($event.target.value)">
									<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
										<mat-option *ngFor="let m of monthOptions" [value]="m.value">
											{{m.value}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && LooseCustomer.card_details.controls.exp_month.invalid) || (LooseCustomer.card_details.controls.exp_month.touched && LooseCustomer.card_details.controls.exp_month.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.exp_month.errors?.required">
											This field is required
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										Expiry Year
									</mat-label>
									<mat-select formControlName="exp_year">
										<mat-option [value]="number" *ngFor="let number of booking_params.years">
											{{ number }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && LooseCustomer.card_details.controls.exp_year.invalid) || (LooseCustomer.card_details.controls.exp_year.touched && LooseCustomer.card_details.controls.exp_year.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.exp_year.errors?.required">
											This field is required
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="m-0">
									<mat-label class="required-field">
										CVV (3-4 digit code)
									</mat-label>
									<input matInput placeholder="0000" type="text" (focus)="$event.target.select()"
										formControlName="cvv">
								</mat-form-field>
								<div class="error-box">
									<div class="error-message"
										*ngIf="( submitBookingForm && LooseCustomer.card_details.controls.cvv.invalid) || (LooseCustomer.card_details.controls.cvv.touched && LooseCustomer.card_details.controls.cvv.dirty)">
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.cvv.errors?.required">
											This field is required
										</p>
										<p class="text-danger text-lg m-0"
											*ngIf="LooseCustomer.card_details.controls.cvv.errors?.pattern">
											Only Numbers are Allowed
										</p>
										<ng-template #cvvLength>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.card_details.controls.cvv.errors?.minlength">
												Minimum length should be 3
											</p>
											<p class="text-danger text-lg m-0"
												*ngIf="LooseCustomer.card_details.controls.cvv.errors?.maxlength">
												Maximum length should be 5
											</p>
										</ng-template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Client Accounts Section Ends -->
			<!-- <div class="section-container mt-2">
				<div id="passenger-information" class="px-4 mt-4">
					<h2>
						Passenger Information
					</h2>
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="outline" class="m-0">
								<mat-label>
									Passenger Name <span class="asterisk"></span>
								</mat-label>
								<input matInput placeholder="Passenger Name" type="text" formControlName="passenger_name"
									(focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.passenger_name.invalid && (Form.passenger_name.touched || Form.passenger_name.dirty)">
									<p class="text-danger text-lg m-0" *ngIf="Form.passenger_name.errors?.required">
										This field is required
									</p>
									<p class="text-danger text-lg m-0" *ngIf="Form.passenger_name.errors?.whitespace">
										Extra White Space are not Allowed
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline" class="m-0">
								<mat-label>
									Passenger Email
								</mat-label>
								<input matInput placeholder="Passenger Email" type="text" formControlName="passenger_email"
									(focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.passenger_email.invalid && (Form.passenger_email.touched || Form.passenger_email.dirty)">
									<p class="text-danger text-lg m-0" *ngIf="Form.passenger_email.errors?.required">
										This field is required
									</p>
									<p class="text-danger text-lg m-0" *ngIf="Form.passenger_email.errors?.email">
										Please enter a valid Email Id
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline" class="mat-custom-phone m-0 pcellLabelClass">
								<label class="mat-form-field-label mat-empty mat-form-field-empty labelClass">Passenger
									Cell
								</label>
								<input matInput formControlName="passenger_cell" ng2TelInput [ng2TelInputOptions]="{
										preferredCountries: ['us', 'ca', 'mx', 'gb']
									}" placeholder="1234567890" class="phoneNumberClass" (intlTelInputObject)="PaxTelInputObject($event)"
									(countryChange)="SetFormValue('passenger_cell_isd','+'+ $event.dialCode); SetFormValue('passenger_cell_country', $event.iso2)"
									(focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.passenger_cell.invalid && (Form.passenger_cell.touched || Form.passenger_cell.dirty)">
									<p class="text-danger text-lg m-0" *ngIf="Form.passenger_cell.errors?.required">
										This field is required
									</p>
									<p class="text-danger text-lg m-0"
										*ngIf="Form.passenger_cell.errors?.pattern;else otherError">
										Only Numbers are Allowed
									</p>
									<ng-template #otherError>
										<p class="text-danger text-lg m-0" *ngIf="Form.passenger_cell.errors?.minlength">
											Minimum length should be 4
										</p>
										<p class="text-danger text-lg m-0" *ngIf="Form.passenger_cell.errors?.maxlength">
											Maximum length should be 15
										</p>
									</ng-template>
								</div>
							</div>
						</div>
						
	
	
					</div>
				</div>
			</div> -->

			<!-- Section - Affiliate/Loose Details -->
			<div class="section-container mt-2">
				<h2>
					Transportation Details
				</h2>
				<div class="row px-4 mt-4">
					<div class="col-md-6">
						<div class="card" style="background-color: #e2e2e2;">
							<div class="row" style="align-items: center;">
								<div class="col-sm-12 col-lg-6 col-md-6 player-holder">
									<div><img class="player-thumb" src="{{driverImgUrl}}"></div>
								</div>
								<div class="col-sm-12 col-lg-6 col-md-6">
									<div class="card-body">
										<h5 class="card-title">Driver Details</h5>
										<h6 class="card-subtitle mb-2 text-muted">Your Reliable Ride Partner!
										</h6>
										<p class="card-text">
											<span>Driver Name:</span>
											<strong class="ml-2">{{ driver_info["name"] | titlecase }}</strong>
										</p>
										<p class="card-text">
											<span>Driver Phone:</span>
											<strong class="ml-2">{{ driver_info["phone"] | titlecase }}</strong>
										</p>
										<p class="card-text">
											<span>Driver Gender:</span>
											<strong class="ml-2">{{ driver_info["gender"] | titlecase }}</strong>
										</p>
									</div>
								</div>
							</div>

						</div>
					</div>

					<div class="col-md-6">
						<div class="card" style="background-color: #e2e2e2;">
							<div class="row" style="align-items: center;">
								<div class="col-sm-12 col-lg-6 col-md-6 player-holder">
									<!-- <div class="player-thumb" [style.backgroundImage]="'url(' + dynamicImageUrl + ')'"  style="border-radius: 0%;"></div> -->
									<div><img class="player-thumb" src="{{vehicleImgUrl}}"></div>

								</div>
								<div class="col-sm-12 col-lg-6 col-md-6">
									<div class="card-body">
										<h5 class="card-title">Vehicle Details</h5>
										<h6 class="card-subtitle mb-2 text-muted">Vehicle Specs at a Glance!
										</h6>
										<p class="card-text">
											<span>Vehicle Type:</span>
											<strong class="ml-2">{{ driver_info["type"] | titlecase }}</strong>
										</p>
										<p class="card-text">
											<span>Vehicle Make:</span>
											<strong class="ml-2">{{ driver_info["make"] | titlecase }}</strong>
										</p>
										<p class="card-text">
											<span>Vehicle Model:</span>
											<strong class="ml-2">{{ driver_info["model"] | titlecase }}</strong>
										</p>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>
			<div class="section-container mt-2">
				<!-- Affiliate Driver Information Ends -->

				<!-- Section Booking details start -->
				<h2 id="booking_detail_section">
					Booking Details
				</h2>
				<!-- <div class="section-container mt-2"> -->
				<!-- <h4>
						Booking Type
					</h4> -->
				<!-- <div class="row px-4" *ngIf="Form.updateType.value != 'edit'">
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Service Type
								</mat-label>
								<mat-select formControlName="service_type" [(ngModel)]="service_type"
									(selectionChange)="onSelectionChangeServiceType($event)"
									[ngStyle]="{'cursor': Form.updateType.value == 'edit' ? 'not-allowed' : 'default'}">
									<mat-option value="one_way">One Way</mat-option>
									<mat-option value="round_trip">Round Trip</mat-option>
									<mat-option value="charter_tour">Charter/Tour</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Transfer Type
								</mat-label>
								<mat-select formControlName="transfer_type" class="font-weight-bolder"
									(selectionChange)="changeTransferType($event.value)" [(ngModel)]="transfer_type">
									<mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'round_trip'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Return Transfer Type
								</mat-label>
								<mat-select formControlName="return_transfer_type" [(ngModel)]="return_transfer_type"
									(selectionChange)="changeReturnTransferType($event.value)">
									<mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'charter_tour'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label>
									Number of Hours
								</mat-label>
								<input type="number" matInput placeholder="Enter Hours" formControlName="number_of_hours"
									[(ngModel)]="number_of_hours" (keyup)="handleNoOfHours($event.target.value)"
									(focus)="$event.target.select()" />
								<span matSuffix>Hrs.</span>
							</mat-form-field>
						</div>
					</div>
				</div> -->
				<!-- </div> -->
				<!-- <div class="row"> -->
				<!-- Meet Greet Choices -->
				<div class="row col-md-12 ml-2">
					<mat-form-field appearance="outline" class="flex-grow-1 m-2">
						<mat-label>
							Meet and Greet Choices <span class="asterisk"></span>
						</mat-label>
						<mat-select formControlName="meet_greet_choices"
							(selectionChange)="handleChangeMeetAndGreet($event ,'')">
							<mat-option *ngFor="let meetGreet of BigData?.meetGreets" [value]="meetGreet.id">
								{{ meetGreet.message }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Number Of Vehicles -->
				<div class="row  px-4 ">
					<div class="col-md-3">
						<mat-form-field appearance="outline">
							<mat-label>
								Number of Vehicles
							</mat-label>
							<input type="text" matInput placholder="Number of Vehicles"
								[value]="Form.number_of_vehicles.value ? Form.number_of_vehicles.value : 1"
								(focus)="$event.target.select()"
								(keyup)="SetFormValue('number_of_vehicles', $event.target.value)" />
						</mat-form-field>
					</div>

					<!-- Travel Date -->
					<div class="col-md-4">
						<mat-form-field appearance="outline">
							<mat-label>
								Travel Date
							</mat-label>
							<input matInput setOutputDateFormat="YYYY-MM-DD" [matDatepicker]="onwy_dt"
								placeholder="Travel Date"
								(dateChangeWithFormat)="SetFormValue('pickup_date', $event);SetFormValue('return_pickup_date', $event)"
								formControlName="pickup_date" (click)="onwy_dt.open()" readonly />
							<mat-datepicker-toggle matSuffix [for]="onwy_dt"></mat-datepicker-toggle>
							<mat-datepicker #onwy_dt></mat-datepicker>
						</mat-form-field>
					</div>

					<!-- Travel Time -->
					<div class="col-md-4" id="travel_time">
						<mat-form-field appearance="outline">
							<mat-label>
								Pickup Time
							</mat-label>
							<input [ngxTimepicker]="onwy_tm" formControlName="pickup_time" matInput readonly />
						</mat-form-field>
						<ngx-material-timepicker #onwy_tm
							[defaultTime]="Form.pickup_time.value"></ngx-material-timepicker>
					</div>
				</div>
				<!-- </div> -->


				<h4 class="pl-2 mt-3" id="pickup_details">
					Pickup
				</h4>

				<!-- Pickup - Address Field -->
				<div *ngIf="!searchSubstring(Form.transfer_type.value, 'airport_')">
					<div class="row px-2">
						<div class="col-md-12 ml-2" id="pickup_address">
							<mat-form-field appearance="outline">
								<mat-label> Pickup Address </mat-label>
								<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
									[value]="fillValue(null, 'pickup', null)" (focus)="$event.target.select()"
									(onAutocompleteSelected)="fillAddress('pickup', $event)"
									(onLocationSelected)="fillLocationPoints('pickup', $event)" />
							</mat-form-field>
						</div>
					</div>
				</div>


				<!-- Pickup - Airport Fields -->
				<div *ngIf="searchSubstring(Form.transfer_type.value, 'airport_')">
					<div class="row px-2 ml-2">
						<!-- Airport -->
						<div class="col-md-6" id="pickup_address">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airport
								</mat-label>
								<input #searchInput type="search" matInput
									placeholder="Search For Airport" (focus)="$event.target.select()"
									aria-label="Airport" [matAutocomplete]="onwy_airport"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airportsData', $event.target.value, 'name')"
									formControlName="pickup_airport_name" />
								<mat-autocomplete #onwy_airport="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airportsData"
										(click)="SetFormValue('pickup_airport', option.id)">
										{{ option.code }} - {{ option.name }}, {{option.city }}, {{
										option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select - Pickup > Airport -->
							<ng-select appearance="outline" [items]="BigData?.airportsData"
								[searchFn]="airportSearchFunction" (change)="change($event, 'pickup_airport')"
								placeholder="Select Airport (Search by airport code or name)" bindLabel="formatted_name"
								dropdownPosition="bottom" [markFirst]="true" [virtualScroll]="true"
								formControlName="pickup_airport_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.pickup_airport_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.pickup_airport_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.pickup_airport_option.errors.required">This field is required</div>
							</div>


						</div>

						<!-- Airline -->
						<div class=" col-md-6">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airline
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airport" aria-label="Airport"
									formControlName="pickup_airline_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airlinesData', $event.target.value, 'name')"
									(focus)="$event.target.select()" [matAutocomplete]="onwy_airline">
								<mat-autocomplete #onwy_airline="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airlinesData"
										(click)="SetFormValue('pickup_airline', option.id)">
										{{ option.code }} - {{ option.name }}, {{ option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select > Airline -->
							<ng-select appearance="outline" [items]="BigData?.airlinesData"
								[searchFn]="airlineSearchFunction" (change)="change($event, 'pickup_airline')"
								placeholder="Select Airline" bindLabel="formatted_name" dropdownPosition="bottom"
								[markFirst]="true" [virtualScroll]="true" formControlName="pickup_airline_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.pickup_airline_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.pickup_airline_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.pickup_airline_option.errors.required">This field is required</div>
							</div>
						</div>

						<!-- Flight / Tail # -->
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="vehicleLable mt-0"
								style="margin-top: 0px !important;">
								<mat-label>
									Flight / Tail # <span class="asterisk">*</span>
								</mat-label>
								<input type="text" matInput placeholder="Flight / Tail #" aria-label="Flight"
									formControlName="pickup_flight" (focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.pickup_flight.invalid && (Form.pickup_flight.touched || Form.pickup_flight.dirty)"
									style="margin-top:-15px">
									<p class="text-danger text-lg m-0" *ngIf="Form.pickup_flight.errors?.required">
										This field is required
									</p>
								</div>
							</div>
						</div>

						<!-- Origin Airport / City -->
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="vehicleLable mt-0"
								style="margin-top: 0px !important;">
								<mat-label>
									Origin Airport / City <span class="asterisk">*</span>
								</mat-label>
								<input formControlName="origin_airport_city" type="text" matInput
									placeholder="Origin Airport / City" (focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.origin_airport_city.invalid && (Form.origin_airport_city.touched || Form.origin_airport_city.dirty)"
									style="margin-top:-15px">
									<p class="text-danger text-lg m-0"
										*ngIf="Form.origin_airport_city.errors?.required">
										This field is required
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- Pickup - Cruise Details -->
				<div *ngIf="searchSubstring(Form.transfer_type.value, 'cruise_')">
					<div class="row px-2">
						<!-- Cruise Port -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Port
								</mat-label>
								<input type="text" matInput placeholder="Cruise Port" formControlName="cruise_port"
									(focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Cruise Ship Name -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Ship Name
								</mat-label>
								<input formControlName="cruise_name" type="text" matInput placeholder="Cruise Ship Name"
									(focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Time -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Ship Arrival time
								</mat-label>
								<input [ngxTimepicker]="crse_tm" matInput formControlName="cruise_time"
									(focus)="$event.target.select()" readonly />
							</mat-form-field>
							<ngx-material-timepicker #crse_tm
								[defaultTime]="Form.cruise_time.value"></ngx-material-timepicker>
						</div>
					</div>
				</div>


				<h4 class="pl-2 mt-3">
					Dropoff
				</h4>

				<!-- Dropoff - Address Field -->
				<div *ngIf="!searchSubstring(Form.transfer_type.value, '_airport')">
					<div class="row px-2">
						<div class="col-md-12 ml-2">
							<mat-form-field appearance="outline">
								<mat-label> Dropoff Address </mat-label>
								<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
									[value]="fillValue(null, 'dropoff', null)" (focus)="$event.target.select()"
									(onAutocompleteSelected)="fillAddress('dropoff', $event)"
									(onLocationSelected)="fillLocationPoints('dropoff', $event)" />
							</mat-form-field>
						</div>
					</div>
				</div>


				<!-- Dropoff - Airport Field -->
				<div *ngIf="searchSubstring(Form.transfer_type.value, '_airport')">
					<div class="row ml-1">
						<!-- Airport -->
						<div class="col-md-6">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airport
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airport" aria-label="Airport"
									formControlName="dropoff_airport_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airportsData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_drop_airport"
									(focus)="$event.target.select()" />
								<mat-autocomplete #onwy_drop_airport="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airportsData"
										(click)="SetFormValue('dropoff_airport', option.id)">
										{{ option.code }} - {{ option.name }}, {{option.city }}, {{
										option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select > Dropoff > Airport -->
							<ng-select appearance="outline" [items]="BigData?.airportsData"
								[searchFn]="airportSearchFunction" (change)="change($event, 'dropoff_airport')"
								placeholder="Select Airport (Search by airport code or name)" bindLabel="formatted_name"
								dropdownPosition="bottom" [markFirst]="true" [virtualScroll]="true"
								formControlName="dropoff_airport_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.dropoff_airport_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.dropoff_airport_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.dropoff_airport_option.errors.required">This field is required</div>
							</div>
						</div>

						<!-- Airline -->
						<div class="col-md-6">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airline
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airline" aria-label="Airport"
									formControlName="dropoff_airline_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airlinesData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_drop_airline"
									(focus)="$event.target.select()" />
								<mat-autocomplete #onwy_drop_airline="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airlinesData"
										(click)="SetFormValue('dropoff_airline', option.id)">
										{{ option.code }} - {{ option.name }}, {{ option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->
							<!-- Ng-Select > Dropoff > Airline -->
							<ng-select appearance="outline" [items]="BigData?.airlinesData"
								[searchFn]="airlineSearchFunction" (change)="change($event, 'dropoff_airline')"
								placeholder="Select Airline" bindLabel="formatted_name" dropdownPosition="bottom"
								[markFirst]="true" [virtualScroll]="true" formControlName="dropoff_airline_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.dropoff_airline_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.dropoff_airline_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.dropoff_airline_option.errors.required">This field is required</div>
							</div>
						</div>
						<!-- Flight / Tail # -->
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="vehicleLable mt-0"
								style="margin-top: 0px !important;">
								<mat-label>
									Flight / Tail #
								</mat-label>
								<input type="text" matInput placeholder="Flight / Tail #" aria-label="Flight"
									formControlName="dropoff_flight" (focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.dropoff_flight.invalid && (Form.dropoff_flight.touched || Form.dropoff_flight.dirty)"
									style="margin-top:-15px">
									<p class="text-danger text-lg m-0" *ngIf="Form.dropoff_flight.errors?.required">
										This field is required
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- Dropoff - Cruise Details -->
				<div *ngIf="searchSubstring(Form.transfer_type.value, '_cruise')">
					<div class="row px-2">
						<!-- Cruise Port -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Port
								</mat-label>
								<input type="text" matInput placeholder="Cruise Port" formControlName="cruise_port"
									(focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Cruise Ship Name -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Ship Name
								</mat-label>
								<input formControlName="cruise_name" type="text" matInput placeholder="Cruise Ship Name"
									(focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Time -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Ship Arrival time
								</mat-label>
								<input [ngxTimepicker]="crse_tm" matInput formControlName="cruise_time"
									(focus)="$event.target.select()" readonly />
							</mat-form-field>
							<ngx-material-timepicker #crse_tm
								[defaultTime]="Form.cruise_time.value"></ngx-material-timepicker>
						</div>
					</div>
				</div>
				<!-- Special Instructions -->
				<div class="col-md-12 my-2 margin-left-pointtwo">
					<mat-form-field appearance="outline">
						<mat-label>
							Special Instructions
						</mat-label>
						<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
							formControlName="booking_instructions" rows="5"></textarea>
					</mat-form-field>
				</div>
				<div class="row m-2">
					<p class="text-lg" style="color: #dc3545!important;">
						NOTE : Enter pickup and drop off locations first, then enter extra stops.
					</p>
				</div>

				<!-- Extra Stops -->
				<div class="row mt-3">
					<div class="col-md-12 px-4 d-flex justify-content-start align-items-center">
						<h4 class="m-0">
							Extra Stops
						</h4>
						<div class="mx-4">
							<button type="button" class="custom-filled-button" (click)="addExtraStop(false)">
								Add Stop
							</button>
						</div>
					</div>
				</div>

				<!-- Extra Stop Address Field -->
				<div class="row mt-2 mb-4 align-items-center m-0"
					*ngFor="let extra_stop of ExtraStops.controls; index as stop_index">
					<div class="extra-stop-field col-11">
						<mat-form-field appearance="outline">
							<mat-label> Extra Stop Address {{ stop_index + 1 }} </mat-label>
							<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
								(onAutocompleteSelected)="fillExtraStop(false, stop_index, $event, null)"
								(focus)="$event.target.select()" [value]="fetchStopValue('extra_stops', stop_index)"
								(onLocationSelected)="fillExtraStop(false, stop_index, null, $event);" />
						</mat-form-field>
					</div>
					<div class="col-1 p-0 p-md-3">
						<div class="delete-icon">
							<i class="bi bi-trash text-2xl text-danger font-weight-bolder"
								(click)="deleteExtraStop(false, stop_index)"></i>
						</div>
					</div>
					<!-- <div class="col-11">
						<mat-form-field appearance="outline">
							<mat-label> Extra Stop Instruction {{ stop_index + 1 }} </mat-label>
							<input type="text" matInput placeholder="Special Instruction" 
							(focus)="$event.target.select()" [value]="fetchSpecialInstruction('extra_stops', stop_index)"
							(keyup)="fillExtraStopInstruction(false, stop_index,$event)"/>
						</mat-form-field>
					</div> -->
				</div>
				<!-- Extra Stop Address Field Ends -->


				<!-- AGM Map -->
				<div class="row">
					<!-- AGM Map -->
					<div class="col-md-12 margin-top-minus-20">
						<div class="map-block">
							<div class="google_map">
								<div id="map" class="map"></div>
							</div>
							<div class="row">
								<h6 class="mx-2">
									Total Distance: {{ mToMi(distance) }} Miles / {{ mToKm(distance)
									}} Km
								</h6>
								<h6 class="mx-2">
									Estimated time: {{convertToMinutes(Form.journeyTime.value)}}
								</h6>
							</div>

						</div>
					</div>
					<!-- AGM Map Ends -->
				</div>
			</div>
			<!-- Section - Affiliate/Loose Details Ends -->


			<!-- Driver Languages -->
			<div id="languages" class="section-container section-dropdown"
				[ngClass]="booking_params.chevrons.languages ? 'active' : ''"
				*ngIf="Form.affiliate_type.value === 'loose_affiliate'">
				<!-- Languages -->
				<div class="row align-items-center m-0" (click)="toggleDropdown('languages')">
					<div class="col-10 col-md-11">
						<h4 class="section-dropdown-header m-0">
							Languages
						</h4>
					</div>
					<div class="col-2 col-md-1">
						<h4 class="m-0">
							<i class="bi"
								[ngClass]="{true: 'bi-chevron-up', false: 'bi-chevron-down'}[booking_params.chevrons.languages]"></i>
						</h4>
					</div>
				</div>
				<!-- Ṣelections -->
				<div *ngIf="booking_params.chevrons.languages">
					<div class="row align-items-center m-0 p-4">
						<div class="col-md-4 col-lg-3"
							*ngFor="let language of BigData?.driverLanguage; index as language_index">
							<mat-checkbox (change)="select($event.checked, 'driver_languages', language.id)"
								[checked]="isChecked('driver_languages', language.id)">
								{{ language.name }}
							</mat-checkbox>
						</div>
					</div>
				</div>
			</div>

			<!-- Driver Dresses -->
			<div id="dresses" class="section-container section-dropdown"
				[ngClass]="booking_params.chevrons.dresses ? 'active' : ''"
				*ngIf="Form.affiliate_type.value === 'loose_affiliate'">
				<!-- Dresses -->
				<div class="row align-items-center m-0" (click)="toggleDropdown('dresses')">
					<div class="col-10 col-md-11">
						<h4 class="section-dropdown-header m-0">
							Driver Dresses
						</h4>
					</div>
					<div class="col-2 col-md-1">
						<h4 class="m-0">
							<i class="bi"
								[ngClass]="{true: 'bi-chevron-up', false: 'bi-chevron-down'}[booking_params.chevrons.dresses]"></i>
						</h4>
					</div>
				</div>
				<!-- Selections -->
				<div *ngIf="booking_params.chevrons.dresses">
					<div class="row align-items-center m-0 p-4">
						<div class="col-md-4" *ngFor="let dresses of BigData?.driverDresses">
							<mat-checkbox (change)="select($event.checked, 'driver_dresses', dresses.id)"
								[checked]="isChecked('driver_dresses', dresses.id)">
								{{ dresses.name }}
							</mat-checkbox>
						</div>
					</div>
				</div>
			</div>


			<!-- Amenities -->
			<div id="amenities" class="section-container section-dropdown"
				[ngClass]="booking_params.chevrons.amenities ? 'active' : ''"
				*ngIf="Form.affiliate_type.value === 'loose_affiliate'">
				<!-- Amenities -->
				<div class="row align-items-center m-0" (click)="toggleDropdown('amenities')">
					<div class="col-10 col-md-11">
						<h4 class="section-dropdown-header m-0">
							Amenities
						</h4>
					</div>
					<div class="col-2 col-md-1">
						<h4 class="m-0">
							<i class="bi"
								[ngClass]="{true: 'bi-chevron-up', false: 'bi-chevron-down'}[booking_params.chevrons.amenities]"></i>
						</h4>
					</div>
				</div>
				<!-- Selections -->
				<div *ngIf="booking_params.chevrons.amenities">
					<div class="m-3 alert alert-danger">
						{{currencySymbol}} Sign Denotes The Amenity Is Tied To A Bucket On The Rate Settings
						Page. The Rate Is Default At 0{{currencySymbol}}
					</div>
					<div class="row align-items-center m-0 p-4">
						<div class="col-md-4" *ngFor="let amenity of BigData?.amenity; index as amenity_index">
							<mat-checkbox
								(change)="amenity['chargeable'] == 'yes' ? select($event.checked, 'chargedAmenities', amenity.id) : select($event.checked, 'amenities', amenity.id)"
								[checked]="amenity['chargeable'] == 'yes' ? isChecked('chargedAmenities', amenity.id) : isChecked('amenities', amenity.id)">
								{{ amenity.name }}
								<span class="text-danger" *ngIf="amenity.chargeable == 'yes'">
									({{currencySymbol}})
								</span>
							</mat-checkbox>
						</div>
					</div>
				</div>
			</div>



			<!-- Driver/Vehicle Image Uploads -->
			<div id="images" class="section-container section-dropdown"
				[ngClass]="booking_params.chevrons.images ? 'active' : ''"
				*ngIf="Form.affiliate_type.value === 'loose_affiliate'">
				<div class="row align-items-center m-0" (click)="toggleDropdown('images')">
					<div class="col-10 col-md-11">
						<h4 class=" section-dropdown-header m-0">
							Upload Images
						</h4>
					</div>
					<div class="col-2 col-md-1">
						<h4 class="m-0">
							<i class="bi"
								[ngClass]="{true: 'bi-chevron-up', false: 'bi-chevron-down'}[booking_params.chevrons.images]"></i>
						</h4>
					</div>
				</div>
				<!-- Uploads -->
				<div *ngIf="booking_params.chevrons.images">
					<!-- Image Upload -->
					<div class="row mt-5 mx-2 justify-content-around">
						<!-- Driver Image -->
						<div class="col-md-4">
							<p class="text-center"> Driver Image </p>
							<div class="vehtype_extint" *ngIf="driver_image?.image; else emptyImg">
								<img [src]="driver_image?.image" (click)="showImageModal(driver_image?.image)">
							</div>
							<ng-template #emptyImg>
								<div class="vehtype_extint emptyImage">
									<img src="../../../../assets/images/empty-image.png">
								</div>
							</ng-template>

							<ul class="mt-3 d-flex justify-content-center p-0"
								*ngIf="driver_image?.image;else addImage">
								<li>
									<button type="button" class="changeuploaded">
										<input type="file" accept="image/jpeg,.jpeg,.jpg,.png"
											(change)="uploadImage($event, 'driver_image')">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="deleteImage('driver_image')">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg> Delete
									</a>
								</li>
							</ul>
							<ng-template #addImage>
								<ul class="mt-3 d-flex justify-content-center p-0">
									<li>
										<button type="button" class="changeuploaded">
											<input type="file" accept="image/jpeg,.jpeg,.jpg,.png"
												(change)="uploadImage($event, 'driver_image')">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</ng-template>
						</div>

						<!-- Vehicle Image -->
						<div class="col-md-4">
							<p class="text-center"> Vehicle Image </p>
							<div class="vehtype_extint" *ngIf="vehicle_image?.image; else emptyImg">
								<img [src]="vehicle_image?.image" (click)="showImageModal(vehicle_image?.image)">
							</div>
							<ng-template #emptyImg>
								<div class="vehtype_extint emptyImage">
									<img src="../../../../assets/images/empty-image.png">
								</div>
							</ng-template>

							<ul class="mt-3 d-flex justify-content-center p-0"
								*ngIf="vehicle_image?.image;else addVehicleImage">
								<li>
									<button type="button" class="changeuploaded">
										<input type="file" accept="image/jpeg,.jpeg,.jpg,.png"
											(change)="uploadImage($event, 'vehicle_image')">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path fill="none" d="M0 0h24v24H0V0z" />
											<circle cx="12" cy="12" r="3" />
											<path
												d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
										</svg>
										Change
									</button>
								</li>
								<li>
									<a class="delete-btn" (click)="deleteImage('vehicle_image')">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
											<path
												d="M30,5H22V4a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V5H2A1,1,0,0,0,2,7H5V26a5.006,5.006,0,0,0,5,5H22a5.006,5.006,0,0,0,5-5V7h3a1,1,0,0,0,0-2ZM12,4a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V5H12ZM25,26a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V7H25Z" />
										</svg> Delete
									</a>
								</li>
							</ul>
							<ng-template #addVehicleImage>
								<ul class="mt-3 d-flex justify-content-center p-0">
									<li>
										<button type="button" class="changeuploaded">
											<input type="file" accept="image/jpeg,.jpeg,.jpg,.png"
												(change)="uploadImage($event, 'vehicle_image')">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
												<path fill="none" d="M0 0h24v24H0V0z" />
												<circle cx="12" cy="12" r="3" />
												<path
													d="M20 4h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65H9.88c-.56 0-1.1.24-1.48.65L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
											</svg>
											Add
										</button>
									</li>
								</ul>
							</ng-template>
						</div>
					</div>
					<!-- Image Upload Ends -->
				</div>
			</div>


			<!-- Section - Booking Details -->

			<!-- Section - Booking Details Ends -->


			<!-- Section - Return Journey Details -->
			<div class="section-container" *ngIf="Form.service_type.value == 'round_trip'">
				<h2>
					Return Booking
				</h2>
				<!-- <div class="row px-4" *ngIf="Form.updateType.value != 'edit'">
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Service Type
								</mat-label>
								<mat-select formControlName="service_type" [(ngModel)]="service_type"
									(selectionChange)="onSelectionChangeServiceType($event)"
									[ngStyle]="{'cursor': Form.updateType.value == 'edit' ? 'not-allowed' : 'default'}">
									<mat-option value="one_way">One Way</mat-option>
									<mat-option value="round_trip">Round Trip</mat-option>
									<mat-option value="charter_tour">Charter/Tour</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Transfer Type
								</mat-label>
								<mat-select formControlName="transfer_type" class="font-weight-bolder"
									(selectionChange)="changeTransferType($event.value)"
									[(ngModel)]="Form.transfer_type.value">
									<mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'round_trip'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label class="">
									Return Transfer Type
								</mat-label>
								<mat-select formControlName="return_transfer_type" [(ngModel)]="return_transfer_type"
									(selectionChange)="changeReturnTransferType($event.value)">
									<mat-option [value]="tf_type" class="font-weight-bolder"
										*ngFor="let tf_type of booking_params.transfer_types">
										{{ textFormatterTransferType(tf_type) | titlecase }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="flex-grow-1 m-2" *ngIf="Form.service_type.value == 'charter_tour'">
						<div>
							<mat-form-field appearance="outline">
								<mat-label>
									Number of Hours
								</mat-label>
								<input type="number" matInput placeholder="Enter Hours" formControlName="number_of_hours"
									[(ngModel)]="number_of_hours" (keyup)="handleNoOfHours($event.target.value)"
									(focus)="$event.target.select()" />
								<span matSuffix>Hrs.</span>
							</mat-form-field>
						</div>
					</div>
				</div> -->
				<div class="row">
					<!-- Meet Greet Choices -->
					<div class="col-md-5 m-2">
						<mat-form-field appearance="outline">
							<mat-label>
								Meet and Greet Choices <span class="asterisk"></span>
							</mat-label>
							<mat-select formControlName="return_meet_greet_choices"
								(selectionChange)="handleChangeMeetAndGreet($event ,'return')">
								<mat-option *ngFor="let meetGreet of BigData?.meetGreets" [value]="meetGreet.id">
									{{ meetGreet.message }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<!-- Travel Date -->
					<div class="col-md-5 m-2">
						<mat-form-field appearance="outline">
							<mat-label>
								Arrival Date
							</mat-label>
							<input matInput setOutputDateFormat="YYYY-MM-DD" [matDatepicker]="retn_dt"
								placeholder="Arrival Date"
								(dateChangeWithFormat)="SetFormValue('return_pickup_date', $event)"
								formControlName="return_pickup_date" (click)="retn_dt.open()" />
							<mat-datepicker-toggle matSuffix [for]="retn_dt"></mat-datepicker-toggle>
							<mat-datepicker #retn_dt></mat-datepicker>
						</mat-form-field>
					</div>

					<!-- Travel Time -->
					<div class="col-md-5 m-2">
						<mat-form-field appearance="outline">
							<mat-label>
								Arrival Time
							</mat-label>
							<input [ngxTimepicker]="rt_onwy_tm" matInput formControlName="return_pickup_time"
								(focus)="$event.target.select()" />
						</mat-form-field>
						<ngx-material-timepicker #rt_onwy_tm
							[defaultTime]="Form.return_pickup_time.value"></ngx-material-timepicker>
					</div>
				</div>



				<h4 class="pl-2">
					Return Pickup
				</h4>

				<!-- Return Pickup - Address Field -->
				<div *ngIf="!searchSubstring(Form.return_transfer_type.value, 'airport_')">
					<div class="row px-2">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label> Pickup Address </mat-label>
								<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
									[value]="fillValue(null, 'return_pickup', null)" (focus)="$event.target.select()"
									(onAutocompleteSelected)="fillAddress('return_pickup', $event)"
									(onLocationSelected)="fillLocationPoints('return_pickup', $event)" />
							</mat-form-field>
						</div>
					</div>
				</div>


				<!-- Return Pickup - Airport Fields -->
				<div *ngIf="searchSubstring(Form.return_transfer_type.value, 'airport_')">
					<div class="row px-2">
						<!-- Airport -->
						<div class="col-md-6 ">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airport
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airport" aria-label="Airport"
									formControlName="return_pickup_airport_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airportsData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_airport" (focus)="$event.target.select()" />
								<mat-autocomplete #onwy_airport="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airportsData"
										(click)="SetFormValue('return_pickup_airport', option.id)">
										{{ option.code }} - {{ option.name }}, {{option.city }}, {{
										option.country }}
									</mat-option>
								</mat-autocomplete>
						</mat-form-field> -->

							<!-- Ng-Select > Return > Pickup > Airport -->
							<ng-select appearance="outline" [items]="BigData?.airportsData"
								[searchFn]="airportSearchFunction" (change)="change($event, 'return_pickup_airport')"
								bindLabel="formatted_name" placeholder="Select Airport (Search by airport code or name)"
								dropdownPosition="bottom" [markFirst]="true" [virtualScroll]="true"
								formControlName="return_pickup_airport_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.return_pickup_airport_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.return_pickup_airport_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.return_pickup_airport_option.errors.required">This field is required
								</div>
							</div>
						</div>

						<!-- Airline -->
						<div class="col-md-6 ">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airline
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airport" aria-label="Airport"
									formControlName="return_pickup_airline_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airlinesData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_airline" (focus)="$event.target.select()" />
								<mat-autocomplete #onwy_airline="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airlinesData"
										(click)="SetFormValue('return_pickup_airline', option.id)">
										{{ option.code }} - {{ option.name }}, {{ option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select > Return > Pickup > Airline -->
							<ng-select appearance="outline" [items]="BigData?.airlinesData"
								[searchFn]="airlineSearchFunction" (change)="change($event, 'return_pickup_airline')"
								bindLabel="formatted_name" placeholder="Select Airline" dropdownPosition="bottom"
								[markFirst]="true" [virtualScroll]="true" formControlName="return_pickup_airline_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.return_pickup_airline_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.return_pickup_airline_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.return_pickup_airline_option.errors.required">This field is required
								</div>
							</div>
						</div>

						<!-- Flight / Tail # -->
						<div class="col-md-6 ">
							<mat-form-field appearance="outline" class="vehicleLable mt-0"
								style="margin-top: 0px !important;">
								<mat-label>
									Flight / Tail # <span class="asterisk">*</span>
								</mat-label>
								<input type="text" matInput placeholder="Flight / Tail #" aria-label="Flight"
									formControlName="return_pickup_flight" (focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.return_pickup_flight.invalid && (Form.return_pickup_flight.touched || Form.return_pickup_flight.dirty)"
									style="margin-top:-15px">
									<p class="text-danger text-lg m-0"
										*ngIf="Form.return_pickup_flight.errors?.required">
										This field is required
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- Return Pickup - Cruise Details -->
				<div *ngIf="searchSubstring(Form.return_transfer_type.value, 'cruise_')">
					<div class="row px-2">
						<!-- Cruise Port -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Port
								</mat-label>
								<input type="text" matInput placeholder="Cruise Port"
									formControlName="return_cruise_port" (focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Cruise Ship Name -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Ship Name
								</mat-label>
								<input formControlName="return_cruise_name" type="text" matInput
									placeholder="Cruise Ship Name" (focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Time -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Ship Arrival time
								</mat-label>
								<input [ngxTimepicker]="re_crse_tm" matInput formControlName="return_cruise_time"
									(focus)="$event.target.select()" readonly />
							</mat-form-field>
							<ngx-material-timepicker #re_crse_tm
								[defaultTime]="Form.return_cruise_time.value"></ngx-material-timepicker>
						</div>
					</div>
				</div>



				<h4 class="pl-2">
					Dropoff
				</h4>

				<!-- Return Dropoff - Address Field -->
				<div *ngIf="!searchSubstring(Form.return_transfer_type.value, '_airport')">
					<div class="row px-2">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label> Dropoff Address </mat-label>
								<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
									[value]="fillValue(null, 'return_dropoff', null)" (focus)="$event.target.select()"
									(onAutocompleteSelected)="fillAddress('return_dropoff', $event)"
									(onLocationSelected)="fillLocationPoints('return_dropoff', $event)" />
							</mat-form-field>
						</div>
					</div>
				</div>


				<!-- Return Dropoff - Airport Field -->
				<div *ngIf="searchSubstring(Form.return_transfer_type.value, '_airport')">
					<div class="row px-2">
						<!-- Airport -->
						<div class="col-md-6">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airport
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airport" aria-label="Airport"
									formControlName="return_dropoff_airport_name"
									(keyup)="searchValue('airportsData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_drop_airport"
									(click)="openAutoCompletePanel()"
									(focus)="$event.target.select()" />
								<mat-autocomplete #onwy_drop_airport="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airportsData"
										(click)="SetFormValue('return_dropoff_airport', option.id)">
										{{ option.code }} - {{ option.name }}, {{option.city }}, {{
										option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select > Return > Dropoff > Airport -->
							<ng-select appearance="outline" [items]="BigData?.airportsData"
								[searchFn]="airportSearchFunction" (change)="change($event, 'return_dropoff_airport')"
								bindLabel="formatted_name" placeholder="Select Airport (Search by airport code or name)"
								dropdownPosition="bottom" [markFirst]="true" [virtualScroll]="true"
								formControlName="return_dropoff_airport_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.return_dropoff_airport_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.return_dropoff_airport_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.return_dropoff_airport_option.errors.required">This field is required
								</div>
							</div>
						</div>

						<!-- Airline -->
						<div class="col-md-6">
							<!-- <mat-form-field appearance="outline">
								<mat-label>
									Airline
								</mat-label>
								<input #searchInput type="text" matInput
									placeholder="Search For Airline" aria-label="Airport"
									formControlName="return_dropoff_airline_name"
									(click)="openAutoCompletePanel()"
									(keyup)="searchValue('airlinesData', $event.target.value, 'name')"
									[matAutocomplete]="onwy_drop_airline"
									(focus)="$event.target.select()" />
								<mat-autocomplete #onwy_drop_airline="matAutocomplete">
									<mat-option *ngFor="let option of BigData?.airlinesData"
										(click)="SetFormValue('return_dropoff_airline', option.id)">
										{{ option.code }} - {{ option.name }}, {{ option.country }}
									</mat-option>
								</mat-autocomplete>
							</mat-form-field> -->

							<!-- Ng-Select > Return > Dropoff > Airline -->
							<ng-select appearance="outline" [items]="BigData?.airlinesData"
								[searchFn]="airlineSearchFunction" (change)="change($event, 'return_dropoff_airline')"
								bindLabel="formatted_name" placeholder="Select Airline" dropdownPosition="bottom"
								[markFirst]="true" [virtualScroll]="true"
								formControlName="return_dropoff_airline_option"
								[ngClass]="{ 'border-red': (submitBookingForm && Form.return_dropoff_airline_option.invalid) }"
								style="height:66px;">
								<ng-template ng-option-tmp let-item="item">
									<div [title]="item.formatted_name">{{item.formatted_name}}</div>
								</ng-template>
							</ng-select>
							<div *ngIf="(submitBookingForm && Form.return_dropoff_airline_option.invalid) "
								class="text-danger my-mat-hint" style="margin-bottom: 10px;">
								<div *ngIf="Form.return_dropoff_airline_option.errors.required">This field is required
								</div>
							</div>
						</div>
						<!-- Flight / Tail # -->
						<div class="col-md-6">
							<mat-form-field appearance="outline" class="vehicleLable mt-0"
								style="margin-top: 0px !important;">
								<mat-label>
									Flight / Tail #
								</mat-label>
								<input type="text" matInput placeholder="Flight / Tail #" aria-label="Flight"
									formControlName="return_dropoff_flight" (focus)="$event.target.select()" />
							</mat-form-field>
							<div class="error-box">
								<div class="error-message"
									*ngIf="Form.return_dropoff_flight.invalid && (Form.return_dropoff_flight.touched || Form.return_dropoff_flight.dirty)"
									style="margin-top:-15px">
									<p class="text-danger text-lg m-0"
										*ngIf="Form.return_dropoff_flight.errors?.required">
										This field is required
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>


				<!-- Return Dropoff - Cruise Details -->
				<div *ngIf="searchSubstring(Form.return_transfer_type.value, '_cruise')">
					<div class="row px-2">
						<!-- Cruise Port -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Port
								</mat-label>
								<input type="text" matInput placeholder="Cruise Port"
									formControlName="return_cruise_port" (focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Cruise Ship Name -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Cruise Ship Name
								</mat-label>
								<input formControlName="return_cruise_name" type="text" matInput
									placeholder="Cruise Ship Name" (focus)="$event.target.select()" />
							</mat-form-field>
						</div>

						<!-- Time -->
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>
									Ship Arrival time
								</mat-label>
								<input [ngxTimepicker]="re_crse_tm" matInput formControlName="return_cruise_time"
									(focus)="$event.target.select()" readonly />
							</mat-form-field>
							<ngx-material-timepicker #re_crse_tm
								[defaultTime]="Form.cruise_time.value"></ngx-material-timepicker>
						</div>
					</div>
				</div>

				<div class="col-md-12 my-2 margin-left-pointtwo">
					<mat-form-field appearance="outline">
						<mat-label>
							Special Instructions
						</mat-label>
						<textarea matInput style="white-space: pre-wrap;" formControlName="return_booking_instructions"
							rows="5"></textarea>
					</mat-form-field>
				</div>
				<div class="row m-2">
					<p class="text-lg" style="color: #dc3545!important;">
						NOTE : Enter pickup and drop off locations first, then enter extra stops.
					</p>
				</div>

				<!-- Extra Stops -->
				<div class="row my-3 justify-items-end">
					<div class="col-md-12 px-4 d-flex justify-content-start align-items-center">
						<h4 class="m-0">
							Extra Stops
						</h4>
						<div class="mx-4">
							<button type="button" class="custom-filled-button" (click)="addExtraStop(true)">
								Add Stop
							</button>
						</div>
					</div>
				</div>
				<!-- Extra Stop Address Field -->
				<div class="row my-2 align-items-center"
					*ngFor="let extra_stop of ReturnExtraStops.controls; index as stop_index">
					<div class="extra-stop-field col-md-11">
						<mat-form-field appearance="outline" class="">
							<mat-label>
								Return Extra Stop Address {{ stop_index + 1 }}
							</mat-label>
							<input type="text" matInput placeholder="Type your address" matGoogleMapsAutocomplete
								(onAutocompleteSelected)="fillExtraStop(true, stop_index, $event, null)"
								(focus)="$event.target.select()"
								[value]="fetchStopValue('return_extra_stops', stop_index)"
								(onLocationSelected)="fillExtraStop(true, stop_index, null, $event)" />
						</mat-form-field>
					</div>
					<div class="col-md-1">
						<div class="delete-icons">
							<i class="bi bi-trash text-2xl text-danger font-weight-bolder"
								(click)="deleteExtraStop(true, stop_index)"></i>
						</div>
					</div>
				</div>
				<!-- Extra Stop Address Field Ends -->


				<!-- AGM Map -->
				<div class="row">
					<!-- AGM Map -->
					<div class="col-md-12">
						<div class="map-block">
							<div class="google_map">
								<div id="return_map" class="map"></div>
							</div>
							<h6>
								Total Distance: {{ mToMi(return_distance) }} Miles / {{
								mToKm(return_distance) }} Km
							</h6>
						</div>
					</div>
					<!-- AGM Map Ends -->
				</div>
				<!-- AGM Map -->
			</div>
			<!-- Section - Return Journey Details Ends -->


			<!-- <app-rates-form class="d-flex flex-wrap" [initRates]="init_rates" [affiliate_type]="Form.affiliate_type.value"
				[distance]="distance" [reservation_id]="booking_id" [reset]="reset_button" [reset]="reset_button"
				[initReturnRates]="init_return_rates" (formvalue)="RateFormValue($event)" [vehicle_id]="QB_vehicle_id"
				[hours]="Form.number_of_hours?.value" [vehicles]="Form.number_of_vehicles?.value" [book_data]="booking_data"
				(returnformvalue)="ReturnRateFormValue($event)" (returnNumberOfHr)="HandleReturnNumberOfHr($event)">
			</app-rates-form> -->

			<div class="row">
				<div class="section-container finalizeRates"
					[ngClass]="{'w-90': service_type!='round_trip', 'w-45 mr-0': service_type=='round_trip'}">
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Sub Total
							</p>
						</div>
						<div class="col-md-4 text-3xl text-right">
							<span style="color:#dc3545;">{{currencySymbol}}</span> {{ subtotal.toFixed(2) }}
						</div>
					</div>
					<!-- Number of Vehicles/Hours -->
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6 col-xl-7">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Number of Vehicles
							</p>
						</div>
						<div class="col-md-4 col-xl-5 text-3xl text-right" id="NumVehicles">
							<i class="bi bi-x text-dark font-weight-bolder"></i>
							{{ Form.number_of_vehicles.value }}
						</div>
					</div>

					<!-- Total -->
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6 col-xl-7">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Grand Total
							</p>
						</div>
						<div class="col-md-4 col-xl-5 text-3xl text-right">
							<span style="color:#dc3545;">{{currencySymbol}}</span> {{
							(Form.number_of_vehicles.value*subtotal).toFixed(2) }}
						</div>
					</div>
				</div>
				<div class="section-container finalizeRates" *ngIf="service_type=='round_trip'"
					[ngClass]="{'w-45': service_type=='round_trip'}" style="margin-left: 0px;">
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Sub Total
							</p>
						</div>
						<div class="col-md-4 text-3xl text-right">
							<span style="color:#dc3545;">{{currencySymbol}}</span> {{ r_subtotal.toFixed(2) }}
						</div>
					</div>
					<!-- Number of Vehicles/Hours -->
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6 col-xl-7">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Number of Vehicles
							</p>
						</div>
						<div class="col-md-4 col-xl-5 text-3xl text-right" id="NumVehicles">
							<i class="bi bi-x text-dark font-weight-bolder"></i>
							{{ Form.number_of_vehicles.value }}
						</div>
					</div>
					<!-- Total -->
					<div class="row px-2 justify-content-between align-items-center">
						<div class="col-md-6 col-xl-7">
							<p class="font-weight-bolder text-uppercase m-0" style="font-size: 1.3rem;">
								Grand Total
							</p>
						</div>
						<div class="col-md-4 col-xl-5 text-3xl text-right">
							<span style="color:#dc3545;">{{currencySymbol}}</span> {{ (Form.number_of_vehicles.value *
							r_subtotal).toFixed(2) }}
						</div>
					</div>
				</div>
			</div>







			<div class="row">
				<div class="col-md-12 d-flex preview_button_edit">
					<!-- <button type="button" class="custom-filled-reset-button m-3" (click)="resetFields()">
						Reset
					</button> -->
					<button type="submit" class="custom-filled-button mr-1 m-3" (click)="submitForm(false)">
						Preview
					</button>
				</div>
			</div>
		</div>
	</form>
</div>

<style>
	.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
		padding-left: 10px !important;
	}
</style>




<div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="paymentModal"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header pb-0 d-flex justify-content-center">
				<h6 class="mb-0 ">{{confirmMsg}}</h6>
			</div>
			<div class="modal-footer">
				<button type="button" class="custom-btn resetbutton" data-dismiss="modal"
					(click)="navigateToDailyBooking()">Cancel</button>
				<button type="button" class="custom-btn savebutton" data-dismiss="modal"
					(click)="proceed=false;submitForm(true)">
					Confirm</button>
			</div>
		</div>
	</div>
</div>








<div class="modal fade" id="previewBooking" aria-labelledby="previewBookingLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title">
					Preview Booking
				</h3>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<!-- *ngIf="Form.affiliate_type.value == 'loose_affiliate' -->
			<div class="modal-body" style="font-family: 'PT Serif', serif;">
				<div class="container text-lg">
					<!-- Rates/Taxes -->
					<h4 class="font-weight-bolder m-0 mt-1">
						Rates/Taxes
					</h4>
					<div class="mx-2" *ngIf="subtotal">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Grand Total
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{currencySymbol}}{{ (Form.number_of_vehicles.value * subtotal).toFixed(2) }}
								</span>
							</div>
						</div>
					</div>
					<!-- && Form.affiliate_type.value!='affiliate' -->
					<div class="mx-2" *ngIf="r_subtotal">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Return Trip Grand Total
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{currencySymbol}}{{ (Form.number_of_vehicles.value * r_subtotal).toFixed(2) }}
								</span>
							</div>
						</div>
						<hr>
					</div>
					<!-- Booking Type -->
					<h4 class="font-weight-bolder m-0">
						Booking Type
					</h4>
					<!-- Travel Info. -->
					<div class="row p-2">
						<div class="col-md-4 key">
							<span>
								Booking Information
							</span>
						</div>
						<div class="col-md-8 value">
							<span class="font-weight-bolder">
								{{ textFormatter(Form.service_type.value) | titlecase }}
								/
								{{ textFormatter(Form.transfer_type.value) | titlecase }}
								<span class="font-weight-bolder" *ngIf="Form.service_type.value == 'round_trip'">
									/ {{ textFormatter(Form.return_transfer_type.value) | titlecase
									}}
								</span>
							</span>
						</div>
					</div>
					<hr>
					<!-- Driver Information -->
					<h4 class="font-weight-bolder m-0 mt-3">
						Driver Information
					</h4>
					<!-- Driver Name -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.driver_name.value) | titlecase }}
								</span>
							</div>
						</div>
					</div>

					<!-- Driver Email -->
					<!-- <div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Email
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.driver_email.value) }}
								</span>
							</div>
						</div>
					</div> -->

					<!-- Driver Cell -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Phone
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									({{ Form.driver_cell_isd.value }}) {{
									Form.driver_cell.value }}
								</span>
							</div>
						</div>
					</div>
					<hr>

					<!-- Loose Customer Information -->
					<ng-container *ngIf="Form['account_type'].value == 'loose_customer' then LooseCustomerSec">
					</ng-container>

					<!-- Loose Customer -->
					<ng-template #LooseCustomerSec>
						<h4 class="font-weight-bolder m-0 mt-3">
							Loose Customer Information
						</h4>

						<!-- Loose Customer > Name -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span>
										Name
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(LooseCustomer.first_name.value) }} {{
										textFormatter(LooseCustomer.middle_name.value) }} {{
										textFormatter(LooseCustomer.last_name.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Loose Customer > Email -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span>
										Email
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(LooseCustomer.email.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Loose Customer > Phone -->
						<div class="mx-2">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span>
										Phone
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										({{ LooseCustomer.phone_isd.value }})
										{{ textFormatter(LooseCustomer.phone.value) }}
									</span>
								</div>
							</div>
						</div>
						<!-- <hr> -->
					</ng-template>
					<!-- Passenger Information -->
					<!-- <h4 class="font-weight-bolder m-0 mt-3">
						Passenger Information
					</h4>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.passenger_name.value) }}
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Email
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.passenger_email.value) }}
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Phone
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									({{ Form.passenger_cell_isd.value }})
									{{ textFormatter(Form.passenger_cell.value) }}
								</span>
							</div>
						</div>
					</div> -->
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									No. of passenger
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ Form.total_passengers.value }}
								</span>
							</div>
						</div>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Luggage count
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ Form.luggage_count.value }}
								</span>
							</div>
						</div>
					</div>
					<hr>


					<!-- Travel Information -->
					<h4 class="font-weight-bolder m-0 mt-3">
						Travel Information
					</h4>

					<!-- Number of Hours -->
					<div class="mx-2" *ngIf="Form.service_type.value == 'charter_tour'">
						<div class="row">
							<div class="col-md-4 key">
								<span>
									Booking Hours
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ Form.number_of_hours.value }}
								</span>
							</div>
						</div>
					</div>

					<!-- ################ Pickup ################### -->
					<h5 class="p-2 m-0 text-xl preview-pickup-color">
						Pickup Details
					</h5>
					<!-- Pickup > Date -->
					<div class="mx-2 preview-pickup-color">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Date
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ dateFormat2(Form.pickup_date.value) }}
									|
									{{ dateFormat(Form.pickup_date.value) }}
								</span>
							</div>
						</div>
					</div>

					<!-- Pickup > Time -->
					<div class="mx-2 preview-pickup-color">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ timeFormat2(Form.pickup_time.value) }}
									|
									{{ timeFormat(Form.pickup_time.value) }}
								</span>
							</div>
						</div>
					</div>

					<!-- Pickup > City Details -->
					<div class="mx-2 preview-pickup-color"
						*ngIf="Form.transfer_type.value.includes('city_') || Form.transfer_type.value.includes('cruise_')">
						<div class="row m-0">
							<div class="col-md-4">
								<span>
									Address
								</span>
							</div>
							<div class="col-md-8">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.pickup.value) }}
								</span>
								<span (click)="showLocationPointOnMap(Form.pickup.value)"><i class="bi bi-geo-alt-fill"
										style="font-size:24px"></i></span>
							</div>
						</div>
					</div>

					<!-- Pickup > Airline Details -->
					<div class="mx-2 preview-pickup-color" *ngIf="Form.transfer_type.value.includes('airport_')">
						<!-- Airport -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Airport
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder preview-airport-color">
									{{ textFormatter(Form.pickup_airport_name.value) }}
								</span>
								<span class="font-weight-bolder preview-airport-color"
									(click)="showLocationPointOnMap(Form.pickup_airport_name.value)"><i
										class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
							</div>
						</div>
						<!-- Airport -->
						<!-- Airline -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Airline
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.pickup_airline_name.value) }}
								</span>
							</div>
						</div>
						<!-- Airline -->
						<!-- Flight / Tail # -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Flight / Tail #
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.pickup_flight.value) }}
								</span>
							</div>
						</div>
						<!-- Flight / Tail # -->
					</div>

					<!-- Pickup > Cruise Details -->
					<div class="mx-2 preview-pickup-color" *ngIf="Form.transfer_type.value.includes('cruise_')">
						<!-- Cruise Port -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Cruise Port
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.cruise_port.value) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Port -->
						<!-- Cruise Ship Name -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Cruise Ship Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.cruise_name.value) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Ship Name -->
						<!-- Ship Arrival time -->
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Ship Arrival time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ timeFormat2(Form.cruise_time.value) }}
									|
									{{ timeFormat(Form.cruise_time.value) }}
								</span>
							</div>
						</div>
					</div>
					<!-- Extra Stops -->
					<hr *ngIf="Form.extra_stops.value.length > 0" />

					<div *ngIf="Form.extra_stops.value.length > 0" class="preview-extra-stop-color">
						<h5 class="p-2 m-0 text-xl">
							Extra Stops
						</h5>
						<div class="mx-2" *ngFor="let stop of Form.extra_stops.value; index as stop_index">
							<div class="row m-0 mx-2">
								<div class="col-md-4 key">
									<span>
										Stop {{ stop_index + 1 }}
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ stop.address }}
									</span>
									<span (click)="showLocationPointOnMap(stop.address)"><i class="bi bi-geo-alt-fill"
											style="font-size:24px"></i></span>
								</div>
							</div>
							<hr>
						</div>
					</div>

					<hr *ngIf="Form.extra_stops.value.length == 0" />

					<!-- ############# Dropoff ################# -->
					<h5 class="px-2 m-1 text-xl preview-dropoff-color">
						Drop Off Details
					</h5>
					<!-- Dropoff > City Details -->
					<div class="mx-2 preview-dropoff-color"
						*ngIf="Form.transfer_type.value.includes('_city') || Form.transfer_type.value.includes('_cruise')">
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Address
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.dropoff.value) }}
								</span>
								<span (click)="showLocationPointOnMap(Form.dropoff.value)"><i class="bi bi-geo-alt-fill"
										style="font-size:24px"></i></span>
							</div>
						</div>
					</div>

					<!-- Dropoff > Airline Details -->
					<div class="mx-2 preview-dropoff-color" *ngIf="Form.transfer_type.value.includes('_airport')">
						<!-- Airport -->
						<div class="row p-2">
							<div class="col-md-4 key ">
								<span>
									Airport
								</span>
							</div>
							<div class="col-md-8 value preview-airport-color">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.dropoff_airport_name.value ) }}
								</span>
								<span class="font-weight-bolder"
									(click)="showLocationPointOnMap(Form.dropoff_airport_name.value)"><i
										class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
							</div>
						</div>
						<!-- Airport -->
						<!-- Airline -->
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Airline
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.dropoff_airline_name.value) }}
								</span>
							</div>
						</div>
						<!-- Airline -->
						<!-- Flight / Tail # -->
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Flight / Tail #
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.dropoff_flight.value) }}
								</span>
							</div>
						</div>
						<!-- Flight / Tail # -->
					</div>

					<!-- Dropoff > Cruise Details -->
					<div class="mx-2 preview-dropoff-color" *ngIf="Form.transfer_type.value.includes('_cruise')">
						<!-- Cruise Port -->
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Cruise Port
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.cruise_port.value) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Port -->
						<!-- Cruise Ship Name -->
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Cruise Ship Name
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ textFormatter(Form.cruise_name.value) | titlecase }}
								</span>
							</div>
						</div>
						<!-- Cruise Ship Name -->
						<!-- Ship Arrival time -->
						<div class="row p-2">
							<div class="col-md-4 key">
								<span>
									Ship Arrival time
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder">
									{{ timeFormat2(Form.cruise_time.value) }}
									|
									{{ timeFormat(Form.cruise_time.value) }}
								</span>
							</div>
						</div>
						<!-- Ship Arrival time -->
					</div>
					<hr>
					<div class="mx-2" *ngIf="Form?.meet_greet_choices_name.value">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Meet and Greet
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder" style="white-space: pre-wrap;">
									{{Form?.meet_greet_choices_name.value}}
								</span>
							</div>
						</div>
						<hr>
					</div>
					<div class="mx-2">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Special Instruction
								</span>
							</div>
							<div class="col-md-8 value box-special-instruction">
								<span class="font-weight-bolder" style="white-space: pre-wrap;">
									{{Form?.booking_instructions.value}}
								</span>
							</div>
						</div>
					</div>


					<!-- Return Information -->
					<!-- style="width: 75%; margin: 1.6rem auto; border: 1.2px solid #000;" / -->
					<div *ngIf="Form.service_type.value == 'round_trip'">
						<hr>
						<h4 class="font-weight-bolder">
							Return Trip
						</h4>
						<h5 class="px-2 m-1 text-xl preview-pickup-color">
							Pickup Details
						</h5>

						<!-- Return > Pickup > Date -->
						<div class="mx-2 preview-pickup-color">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span>
										Date
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ dateFormat2(Form.return_pickup_date.value) }}
										|
										{{ dateFormat(Form.return_pickup_date.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Return > Pickup > Time -->
						<div class="mx-2 preview-pickup-color">
							<div class="row m-0">
								<div class="col-md-4 key">
									<span>
										Time
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ timeFormat2(Form.return_pickup_time.value) }}
										|
										{{ timeFormat(Form.return_pickup_time.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Return > Pickup > City Details -->
						<div class="mx-2 preview-pickup-color"
							*ngIf="Form.return_transfer_type.value.includes('city_') || Form.return_transfer_type.value.includes('cruise_')">
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Address
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_pickup.value) | titlecase }}
									</span>
									<span (click)="showLocationPointOnMap(Form.return_pickup.value)"><i
											class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
								</div>
							</div>
						</div>

						<!-- Return > Pickup > Airline Details -->
						<div class="mx-2 preview-pickup-color"
							*ngIf="Form.return_transfer_type.value.includes('airport_')">
							<!-- Airport -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Airport
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-airport-color">
										{{ textFormatter(Form.return_pickup_airport_name.value) }}
									</span>
									<span class="font-weight-bolder preview-airport-color"
										(click)="showLocationPointOnMap(Form.return_pickup_airport_name.value)"><i
											class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
								</div>
							</div>
							<!-- Airline -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Airline
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_pickup_airline_name.value) }}
									</span>
								</div>
							</div>

							<!-- Flight / Tail # -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Flight / Tail #
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_pickup_flight.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Return > Pickup > Cruise Details -->
						<div class="mx-2 preview-pickup-color"
							*ngIf="Form.return_transfer_type.value.includes('cruise_')">
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Cruise Port
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_cruise_port.value ) | titlecase
										}}
									</span>
								</div>
							</div>
							<!-- Cruise Ship Name -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Cruise Ship Name
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_cruise_name.value) | titlecase
										}}
									</span>
								</div>
							</div>

							<!-- Ship Arrival time -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Ship Arrival time
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ timeFormat2(Form.return_cruise_time.value) }}
										|
										{{ timeFormat(Form.return_cruise_time.value) }}
									</span>
								</div>
							</div>
						</div>
						<hr *ngIf="Form.return_extra_stops.value.length > 0">
						<!-- Return > Extra Stops -->
						<div *ngIf="Form.return_extra_stops.value.length > 0" class="preview-extra-stop-color">
							<h5 class="p-2 m-0 text-xl">
								Extra Stops
							</h5>
							<div class="mx-2" *ngFor="let stop of Form.return_extra_stops.value; index as stop_index">
								<div class="row m-0 mx-2">
									<div class="col-md-4 key">
										<span>
											Stop {{ stop_index + 1 }}
										</span>
									</div>
									<div class="col-md-8 value">
										<span class="font-weight-bolder">
											{{ stop.address }}
										</span>
										<span (click)="showLocationPointOnMap(stop.address)"><i
												class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
									</div>
								</div>
								<hr>
							</div>
						</div>
						<!-- style="width: 50%; margin: 1.6rem auto; border: 1.2px solid #aeaeae;" -->
						<hr *ngIf="Form.return_extra_stops.value.length == 0" />

						<!-- ############## Dropoff #################### -->
						<h5 class="px-2 m-1 text-xl preview-dropoff-color">
							Dropoff Details
						</h5>
						<!-- Return > Dropoff > City Details  -->
						<div class="mx-2 preview-dropoff-color"
							*ngIf="Form.return_transfer_type.value.includes('_city')">
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Address
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_dropoff.value) | titlecase }}
									</span>
									<span (click)="showLocationPointOnMap(Form.return_dropff.value)"><i
											class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
								</div>
							</div>
						</div>

						<!-- Return > Dropoff > Airline Details -->
						<div class="mx-2 preview-dropoff-color"
							*ngIf="Form.return_transfer_type.value.includes('_airport')">
							<!-- Airport -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Airport
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder preview-airport-color">
										{{ textFormatter(Form.return_dropoff_airport_name.value) }}
									</span>
									<span class="font-weight-bolder preview-airport-color"
										(click)="showLocationPointOnMap(Form.return_dropoff_airport_name.value)"><i
											class="bi bi-geo-alt-fill" style="font-size:24px"></i></span>
								</div>
							</div>

							<!-- Airline -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Airline
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_dropoff_airline_name.value) }}
									</span>
								</div>
							</div>

							<!-- Flight / Tail # -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Flight / Tail #
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_dropoff_flight.value) }}
									</span>
								</div>
							</div>
						</div>

						<!-- Return > Dropoff > Cruise Details -->
						<div class="mx-2 preview-dropoff-color"
							*ngIf="Form.return_transfer_type.value.includes('_cruise')">
							<!-- Cruise Port -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Cruise Port
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_cruise_port.value) | titlecase
										}}
									</span>
								</div>
							</div>

							<!-- Cruise Ship Name -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Cruise Ship Name
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ textFormatter(Form.return_cruise_name.value) | titlecase
										}}
									</span>
								</div>
							</div>

							<!-- Ship Arrival time -->
							<div class="row p-2">
								<div class="col-md-4 key">
									<span>
										Ship Arrival time
									</span>
								</div>
								<div class="col-md-8 value">
									<span class="font-weight-bolder">
										{{ timeFormat2(Form.return_cruise_time.value) }}
										|
										{{ timeFormat(Form.return_cruise_time.value) }}
									</span>
								</div>
							</div>
						</div>
						<hr>
					</div>

					<div class="mx-2" *ngIf="Form.service_type.value == 'round_trip'">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Return Meet and Greet
								</span>
							</div>
							<div class="col-md-8 value">
								<span class="font-weight-bolder" style="white-space: pre-wrap;">
									{{Form?.return_meet_greet_choices_name.value}}
								</span>
							</div>
						</div>
						<hr>

					</div>

					<div class="mx-2" *ngIf="Form.service_type.value == 'round_trip'">
						<div class="row m-0">
							<div class="col-md-4 key">
								<span>
									Return Special Instruction
								</span>
							</div>
							<div class="col-md-8 value box-special-instruction">
								<span class="font-weight-bolder" style="white-space: pre-wrap;">
									{{Form?.return_booking_instructions.value}}
								</span>
							</div>
						</div>
						<hr>
					</div>
				</div>
			</div>



			<div class="modal-footer justify-content-end">
				<button type="button" class="custom-filled-back-button" data-dismiss="modal">
					Close
				</button>
				<button type="submit" class="custom-filled-button" data-dismiss="modal" (click)="submitForm(true)">
					Save
				</button>
			</div>
		</div>
	</div>
</div>