import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-website-template',
  templateUrl: './website-template.component.html',
  styleUrls: ['./website-template.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class WebsiteTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
